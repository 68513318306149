import moment from "moment/moment";

export const frequencyList = [
  { id: 2, name: "daily" },
  { id: 3, name: "weekly" },
  { id: 5, name: "monthly" },
];

export const frequencyIdByName = (frequencyName) => {
  return frequencyList.find((frequency) => frequency.name === frequencyName)
    ?.id;
};

export const ONE_DAY_MICROTIME = 24 * 60 * 60 * 1000;

export const monthRange = (monthStr) => {
  const year = parseInt(monthStr.split("-")[0]);
  const month = parseInt(monthStr.split("-")[1]);
  const rangeStart = moment(new Date(year, month - 1));
  const startOfMonth = rangeStart.startOf("month").format("YYYY-MM-DD");
  const endOfMonth = rangeStart.add(1, "month").format("YYYY-MM-DD");
  return [startOfMonth, endOfMonth];
};

export const getDaysArray = (start, end, endIncluded = false) => {
  const arr = [];
  const dt = moment(new Date(start));
  const endDate = moment(new Date(end));

  while (dt < endDate) {
    arr.push(dt.format("YYYY-MM-DD"));
    dt.add(1, "days");
  }
  if (endIncluded) {
    //arr.push(dt.format("YYYY-MM-DD"))
    arr.push(end);
  }
  return arr;
};

export const getMondaysArray = (start, end) => {
  const arr = [];
  let dt = moment(new Date(start));
  const endDate = moment(new Date(end));
  if (dt.day() !== 1) {
    // Move dt to the first Monday
    dt = dt.add(1, "week").startOf("isoWeek");
  }
  while (dt <= endDate) {
    // Collect all Mondays until the end date
    arr.push(dt.format("YYYY-MM-DD"));
    dt.add(1, "week"); // Jump to the next Monday
  }
  return arr;
};

export const getFirstOfMonthsArray = (start, end) => {
  const arr = [];
  let dt = moment(new Date(start)).startOf("month");
  const endDate = moment(new Date(end));
  while (dt <= endDate) {
    // Collect all first days of each month
    arr.push(dt.format("YYYY-MM-DD"));
    dt.add(1, "month").startOf("month"); // Jump to the next month's first day
  }
  return arr;
};

export const getMonthsArray = (start, end) => {
  const arr = [];
  const dt = moment(new Date(start));
  const endDate = moment(new Date(end));

  while (dt < endDate) {
    arr.push(dt.format("YYYY-MM"));
    dt.add(1, "months");
  }

  return arr;
};

export const monthsFrArray = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

export const daysFrArray = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
];

export const getDate = (n_days = 0) => {
  const now = new Date();
  n_days = parseInt(n_days, 10);
  if (n_days === 0) {
    return now;
  }

  return new Date(now.getTime() + n_days * ONE_DAY_MICROTIME);
};

/**
 * @param {Date | undefined} date
 * @return  {String}
 */
export const getDateStr = (date = new Date()) => {
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2); // Months are zero-based
  const day = `0${date.getDate()}`.slice(-2);
  return `${year}-${month}-${day}`;
};

export const getTimeStr = (date = new Date()) =>
  date.toLocaleTimeString("fr-FR");

export const getStrDateFromDateStr = (dateStr) =>
  dateStr ? dateStr.substring(0, 10) : "";

export const getStrTimeFromDateStr = (dateStr) =>
  dateStr ? dateStr.substring(11, 19) : "";

/**
 * @param {Date | undefined} date
 * @return  {String}
 */
export const getMonthDateStr = (date = new Date()) =>
  date.toISOString().substring(0, 7);

/**
 * @param {Date | undefined} date
 * @return  {Object}
 */
export const getDateDetails = (date = new Date()) => {
  const dateStr = getDateStr(date);
  const [yyyy, mm, dd] = dateStr.split("-");

  return {
    year: yyyy,
    month: mm,
    day: dd,
    nbDaysInMonth: new Date(yyyy, mm, 0).getDate(),
  };
};

export function getQueryStringMonth(minYearDiff = -5, maxYearDiff = 5) {
  const currentYear = new Date().getFullYear();
  const urlParams = new URLSearchParams(window.location.search);
  const monthParam = urlParams.get("month");
  if (monthParam) {
    const monthRegex = /^\d{4}-\d{2}$/;
    if (monthRegex.test(monthParam)) {
      let [year, month] = monthParam.split("-");
      year = parseInt(year, 10);
      month = parseInt(month, 10);
      if (month >= 1 && month <= 12) {
        const minYear = currentYear + minYearDiff;
        const maxYear = currentYear + maxYearDiff;
        if (year >= minYear && year <= maxYear) {
          return new Date(year, month, 1);
        } else {
          console.warn(
            `Selected year:${year} must be between ${minYear} and ${maxYear}.`
          );
          return new Date(currentYear, month, 1);
        }
      } else {
        console.warn(`Selected month:${month} must be between 1 and 12.`);
      }
    } else {
      console.warn(`Selected month:${monthParam} is not a valid date.`);
    }
  }
  return null;
}

export const getDayFr = (date = new Date()) => {
  const day = date.getDay(); // day of the week: 0 for Sunday, 1 for Monday, 2 for Tuesday, and so on.
  if (day > 0) {
    return daysFrArray[day - 1];
  }

  return daysFrArray[day + 6];
};

export const getWeekNumber = (date = new Date()) => {
  const d = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  const dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

  return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
};

export const weekRangeDate = (year, week, weekDay = 0) => {
  const getZeroBasedIsoWeekDay = (date) => (date.getDay() + 6) % 7;
  const getIsoWeekDay = (date) => getZeroBasedIsoWeekDay(date) + 1;
  const zeroBasedWeek = week - 1;
  const zeroBasedWeekDay = weekDay - 1;
  let days = zeroBasedWeek * 7 + zeroBasedWeekDay;
  // Dates start at 2017-01-01 and not 2017-01-00
  days += 1;

  const firstDayOfYear = new Date(year, 0, 1);
  const firstIsoWeekDay = getIsoWeekDay(firstDayOfYear);
  const zeroBasedFirstIsoWeekDay = getZeroBasedIsoWeekDay(firstDayOfYear);

  // If year begins with W52 or W53
  if (firstIsoWeekDay > 4) {
    days += 8 - firstIsoWeekDay;
    // Else begins with W01
  } else {
    days -= zeroBasedFirstIsoWeekDay;
  }

  return [new Date(year, 0, days), new Date(year, 0, days + 6)];
};

// export const formatDate = (date) => {
//   const year = date.getFullYear();
//   const month = (`0${date.getMonth() + 1}`).slice(-2); // Months are zero-based
//   const day = (`0${date.getDate()}`).slice(-2);
//   return `${year}-${month}-${day}`;
// }

// Get the start of the week (Monday)
export const getStartOfWeek = (dateStr) => {
  const date = new Date(dateStr);
  const day = date.getDay(); // 0 (Sun) - 6 (Sat)
  const diff = (day === 0 ? -6 : 1) - day; // Adjust when day is Sunday
  date.setDate(date.getDate() + diff);
  date.setHours(0, 0, 0, 0);
  return getDateStr(date);
};

// Get the end of the week (Sunday)
export const getEndOfWeek = (dateStr) => {
  const date = new Date(dateStr);
  const day = date.getDay(); // 0 (Sun) - 6 (Sat)
  const diff = day === 0 ? 0 : 7 - day;
  date.setDate(date.getDate() + diff);
  date.setHours(23, 59, 59, 999);
  return getDateStr(date);
};

// Get the first day of the month
export const getStartOfMonth = (dateStr) => {
  const date = new Date(dateStr);
  date.setDate(1);
  date.setHours(0, 0, 0, 0);
  return getDateStr(date);
};

// Get the last day of the month
export const getEndOfMonth = (dateStr) => {
  const date = new Date(dateStr);
  date.setMonth(date.getMonth() + 1);
  date.setDate(0); // Setting date to 0 sets it to the last day of the previous month
  date.setHours(23, 59, 59, 999);
  return getDateStr(date);
};

export const daysNumberBetween = (
  dateStart,
  dateEnd,
  dateFormat = "YYYY-MM-DD"
) => {
  dateStart =
    typeof dateStart === "string"
      ? moment(dateStart, dateFormat)
      : moment(dateStart);
  dateEnd =
    typeof dateEnd === "string" ? moment(dateEnd, dateFormat) : moment(dateEnd);
  return dateEnd.diff(dateStart, "days") + 1; // add 1 to include both start and end dates
};

export const getDatesFromPeriod = (period) => {
  const now = new Date();
  const dayOffset = 24 * 60 * 60 * 1000;
  const end = new Date(now.getTime() - 2 * dayOffset);
  const endDate = end.toISOString().split("T")[0];
  const startDate = new Date(end.getTime() - period * dayOffset)
    .toISOString()
    .split("T")[0];

  return {
    startDate,
    endDate,
  };
};

/*
export const getPreviousDays(n) {
  const list = [];
  const d = new Date();
  const previousDay = new Date(d.getTime());
  previousDay.setDate(d.getDate() - 1);
  for (let i = 0; i < n; i++) {
    list.push(this.dateToStr(previousDay));
    previousDay.setDate(d.getDate() - (1 + i));
  }
  return list;
};

export const getPreviousMonday() {
  const date = new Date();
  const day = date.getDay() || 7;
  if (day !== 1) date.setHours(-24 * (day - 1));
  date.setHours(date.getHours() + 25);
  date.setHours(-24 * 7);
  return date;
};

export const getPreviousMondays(previousMondayDate, n) {
  const list = [];
  const d = new Date(previousMondayDate.getTime());
  for (let i = 0; i < n; i++) {
    list.push(this.dateToStr(d));
    d.setHours(-24 * 7);
  }
  return list;
};

export const getPreviousFirstOfMonth() {
  let date = new Date();
  date = new Date(date.getFullYear(), date.getMonth() - 1, 2);
  date.setHours(-5);
  return date;
};

export const getPreviousFirstOfMonthList(previousFirstOfMonthDate, n) {
  const list = [];
  const d = new Date(previousFirstOfMonthDate.getTime());
  for (let i = 0; i < n; i++) {
    list.push(this.dateToStr(d));
    d.setMonth(d.getMonth() - 1);
  }
  return list;
};
*/
